import React from 'react';
import { WrapDiv } from './styled';

const Wrap = ({
    pt = 0,
    pb = 0,
    pl = 20,
    pr = 20,
    mb = 0,
    mt = 0,
    bgc,
    ...props
}) =>{

    const styles = {
        paddingTop:pt,
        paddingBottom:pb,
        paddingRight:pr,
        paddingLeft:pl,
        marginBottom:mb,
        marginTop:mt,
        backgroundColor:bgc
    }

    return(
        <WrapDiv style={styles}>
            {props.children}
        </WrapDiv>
    )
}

export default Wrap;