import React, { Component, useEffect }from 'react';
import Grid from '@Theme/SlickGrid/Grid';
import GridInner from '@Theme/SlickGrid/GridInner';
import { useThemeUI } from 'theme-ui';

const FormBottom = ({
    actionsRight,
    actionsLeft
}) =>{

    const context = useThemeUI()
    const { theme } = context

    useEffect(() => {


    },[actionsRight, actionsLeft]);

    const themeStyles = {
        borderBottomRightRadius:5,
        borderBottomLeftRadius:5,
        background:theme.colors.mdgrey
    }

    return(
        <div style={themeStyles}>
            <Grid
                layout={'repeat(2,1fr)'}
                pd={'20px'}
            >
                <GridInner display={'flex'}>
                    {actionsLeft.map((component, index) => (
                        <div key={index}>{component}</div>
                    ))}  
                </GridInner>
                <GridInner jc={'flex-end'} display={'flex'}>
                    {actionsRight.map((component, index) => (
                        <div key={index}>{component}</div>
                    ))}  
                </GridInner>

            </Grid>
        </div>
    )
}

export default FormBottom;