import styled from 'styled-components';

export const InputWrap = styled.div`
    display:grid;
    grid-template-columns:1fr;
`

export const inputStyles = {
    border:'none',
    height:32,
    borderRadius:5,
    marginBottom:20,
    paddingLeft:10,
    paddingRight:10
}

export const labelStyles = {
    marginBottom:10
}