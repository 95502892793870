import React from 'react';
import { Field } from 'formik';
import { InputWrap, inputStyles, labelStyles } from './styled';
import { useThemeUI } from 'theme-ui';

const ZInput = ({
        label,
        name,
        type = 'text',
    }) => {

    const context = useThemeUI()
    const { theme } = context

    const fontStyles = {
        fontFamily:theme.fonts.body
    }

    return(        
    <InputWrap>
        <label style={labelStyles}>{label}</label>
        <Field style={{...inputStyles, ...fontStyles}} type={type} name={name} />
    </InputWrap>
    )
}

export default ZInput;