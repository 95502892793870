import { BrowserRouter as Router, Switch } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import Login from '../Login';
import Home from '../Home';
import Sidebar from '@Components/Sidebar';
import Topbar from '@Components/Topbar';
import AdminDashboard from '../Admin/AdminDashboard';
import UserDashboard from '../UserDashboard';
import AdminCourses from '../Admin/AdminCourses';
import AdminVideos from '../Admin/AdminVideos';
import AdminTemplates from '../Admin/AdminTemplates';
import AdminUsers from '../Admin/AdminUsers';
import AdminSettings from '../Admin/AdminSettings';
import CourseAdd from '../Admin/AdminCourses/CourseAdd';
import RoleRouting from './RoleRouting';
import CourseDetails from '@Containers/Admin/AdminCourses/CourseDetail'

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

const SidebarLayout = props => (
  <div>
    <Sidebar>
      {props.children}
    </Sidebar>
  </div>
)

const TopbarLayout = props => (
  <Topbar>
    {props.children}
    </Topbar>
)

const AppRoute = ({ component, layout, ...rest }) => (
  <RoleRouting component={component} layout={layout} {...rest}/>
)

const Routes = () =>{

    return(
        <Router>
            <Switch>
              <AppRoute exact path="/login" layout={TopbarLayout} component={Login}/>
              <AppRoute exact path="/" layout={TopbarLayout} component={Home}/>
              <AppRoute exact path="/dashboard" layout={TopbarLayout} component={UserDashboard} roles={[0]}/>
              <AppRoute exact path="/admin" layout={SidebarLayout} component={AdminDashboard} roles={[1]}/>
              <AppRoute exact path="/courses" layout={SidebarLayout} component={AdminCourses} roles={[1]}/> 
              <AppRoute exact path="/videos" layout={SidebarLayout} component={AdminVideos} roles={[1]}/>
              <AppRoute exact path="/templates" layout={SidebarLayout} component={AdminTemplates} roles={[1]}/>
              <AppRoute exact path="/users" layout={SidebarLayout} component={AdminUsers} roles={[1]}/>
              <AppRoute exact path="/settings" layout={SidebarLayout} component={AdminSettings} roles={[1]}/>
              <AppRoute exact path="/course/add" layout={SidebarLayout} component={CourseAdd} roles={[1]}/>
              <AppRoute exact path="/course/:course_id?" layout={SidebarLayout} component={CourseDetails} roles={[1]}/>
            </Switch>
        </Router>
    )
}
export default Routes;