import React from 'react';
import LoginForm from '@Components/LoginForm'
import { LoginWrap } from './styled';


const Login = () =>{
    return(
        <LoginWrap>
            <LoginForm/>
        </LoginWrap>
    )
}

export default Login;