import React from 'react';
import { useHistory } from 'react-router-dom';
import { useThemeUI } from 'theme-ui';
import { CourseCardDiv } from './styled';

const CourseCard = (props) =>{

    const context = useThemeUI()
    const { theme } = context
    const history = useHistory()

    const styles = {
        background:theme.colors.secondary
    }

    const goToDetails = () =>{
        history.push(`/course/${props.courseId}`)
    }

    return(
        <CourseCardDiv onClick={() => goToDetails()} style={styles}>
            {props.title}
        </CourseCardDiv>
    )
}

export default CourseCard;