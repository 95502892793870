import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '@Context/User';

const Home = () =>{
    const { user } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        if(user){
            if(user.is_admin === 0){
                history.push('/dashboard')
            }else if(user.is_admin === 1){
                history.push('/admin')
            }
        }else{
            history.push('/login')
        }
    }, [user, history]);
    
    return(
        <>
        </>
    )
}

export default Home;