import styled, { keyframes } from 'styled-components';

export const TimelineItem = styled.div`
    padding: 12px;
    min-height: 50px;
`
const placeHolderShimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

export const AnimatedBackground = styled.div`
    animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: ${placeHolderShimmer};
	animation-timing-function: linear;
	background: #313436;
	background: linear-gradient(to right, #313436 8%, #2b3034 18%, #313436 33%);
	background-size: 800px 36px;
	height: 46px;
	position: relative;
`

export const BackgroundMasker = styled.div`
    position: absolute;
    &.header-top{
        top: 0;
        left: 40px;
        right: 0;
        height: 10px;
    }
    &.header-bottom{
        top: 0;
        left: 40px;
        right: 0;
        height: 10px;
    }
    &.subheader-bottom{
        top: 0;
        left: 40px;
        right: 0;
        height: 10px;
    }
    &.header-left{
        top: 10px;
        left: 40px;
        height: 8px;
        width: 10px;
    }
    &.subheader-left{
        top: 10px;
        left: 40px;
        height: 8px;
        width: 10px;
    }
    &.header-right{
        top: 10px;
        left: 40px;
        height: 8px;
        width: 10px;
    }
    &.subheader-right{
        top: 10px;
        left: 40px;
        height: 8px;
        width: 10px;
    }
    &.header-bottom{
        top: 18px;
	    height: 6px;
    }
    &.subheader-left{
        top: 24px;
	    height: 6px;
    }
    &.subheader-right{
        top: 24px;
	    height: 6px;
    }
    &.header-right, &.subheader-right{
        width: auto;
        left: 300px;
        right: 0;
    }
    &.subheader-right{
        left: 230px;
    }
    &.subheader-bottom{
        top: 30px;
	    height: 20px;
    }
    &.content-top, &.content-second-line, &.content-third-line, &.content-second-end, &.content-third-end, &.content-first-end{
        top: 40px;
        left: 0;
        right: 0;
        height: 6px;
    }
    &.content-top{
        height:20px;
    }
    &.content-first-end, &.content-second-end, &.content-third-end{
        width: auto;
        left: 380px;
        right: 0;
        top: 60px;
        height: 8px;
    }
    &.content-second-line{
        top: 68px;
    }
    &.content-second-end{
        left: 420px;
	    top: 74px;
    }
    &.content-third-line{
        top: 82px;
    }
    &.content-third-end{
        left: 300px;
	    top: 88px;
    }
`