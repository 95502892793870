import React from 'react';
import { PlacematDiv } from './styled';
import { useThemeUI } from 'theme-ui';

const Placemat = ({
    pt,
    pb,
    mt,
    mb,
    ...props
    }) =>{

    const context = useThemeUI()
    const { theme } = context

    const styles = {
        background:theme.colors.secondary,
        paddingTop:pt,
        paddingBottom:pb,
        marginTop:mt,
        marginBottom:mb
    }

    return(
        <PlacematDiv style={styles}>
            {props.children}
        </PlacematDiv>
    )
}

export default Placemat;