import React from 'react';
import {
    TimelineItem,
    AnimatedBackground,
    BackgroundMasker
} from './styled';

const SectionLoader = ({
    bgColor
}) =>{


    const maskerColor = {
        backgroundColor: bgColor
    }
    
    
    return(
        <div>
            <div className="timeline-wrapper">
                <TimelineItem style={maskerColor}>
                    <AnimatedBackground>
                        <BackgroundMasker style={maskerColor} className="header-top"></BackgroundMasker>
                        <BackgroundMasker style={maskerColor} className="header-left"></BackgroundMasker>
                        <BackgroundMasker style={maskerColor} className="header-right"></BackgroundMasker>
                        <BackgroundMasker style={maskerColor} className="header-bottom"></BackgroundMasker>
                        <BackgroundMasker style={maskerColor} className="subheader-left"></BackgroundMasker>
                        <BackgroundMasker style={maskerColor} className="subheader-right"></BackgroundMasker>
                        <BackgroundMasker style={maskerColor} className="subheader-bottom"></BackgroundMasker>
                    </AnimatedBackground>
                </TimelineItem>
            </div>
        </div>
    )
}

export default SectionLoader;