import React, { useState } from 'react';

const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

	return (
		<UserContext.Provider value={{ user, setUser }}>
			{children}
		</UserContext.Provider>
	);
};

export default UserContext;