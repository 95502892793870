import React from 'react';
import { Field } from 'formik';
import { InputWrap, inputStyles, labelStyles } from './styled';
import { useThemeUI } from 'theme-ui';

const FormInput = ({
    label,
    name,
    type = 'text',
}) =>{

    const context = useThemeUI()
    const { theme } = context

    const themeInputStyles = {
        fontFamily:theme.fonts.body,
        background:theme.colors.secondary,
        borderBottom:`1px solid ${theme.colors.ltgrey}`,
    }

    const themeLabelStyles = {
        color:theme.colors.ltgrey
    }

    return(
        <InputWrap>
            <label style={{...labelStyles, ...themeLabelStyles}}>{label}</label>
            <Field style={{...inputStyles, ...themeInputStyles}} type={type} name={name}/>
        </InputWrap>
    )
}

export default FormInput;