import React from 'react';
import { ReactSVG } from 'react-svg';
import Zealogo from '@Assets/images/zealogia_logo.svg';
import Logout from '../Logout';
import Container from '@Theme/SlickGrid/Container';
import Wrap from '@Theme/SlickGrid/Wrap';
import Grid from '@Theme/SlickGrid/Grid';
import GridInner from '@Theme/SlickGrid/GridInner';
import { useThemeUI } from 'theme-ui';


const Topbar = (props) =>{

    const context = useThemeUI()
    const { theme } = context

    return(
        <div>
            <Wrap bgc={theme.colors.secondary}>
                <Container
                    pt={0}
                    pb={0}
                    height={60}
                    display={'flex'}
                    ai={'center'}>
                    <Grid
                        layout={'repeat(2, 1fr)'}>
                        <GridInner>
                            <ReactSVG 
                                    beforeInjection={(svg) => {
                                        svg.setAttribute('style', 'width: 100px;')
                                    }} 
                                    src={Zealogo} />
                        </GridInner>
                        <GridInner
                            display={'flex'}
                            jc={'flex-end'}
                            >
                            <Logout/>
                        </GridInner>

                    </Grid>
                </Container>
            </Wrap>
            {props.children}
        </div>
    )
}
export default Topbar;