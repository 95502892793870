import React from 'react';
import Grid from '@Theme/SlickGrid/Grid';
import Container from '@Theme/SlickGrid/Container';
import GridInner from '@Theme/SlickGrid/GridInner';

const ActionHeader = (props) =>{
    return(
        <Container
            pt={0}
            pb={20}
        >
            <Grid
                layout={'repeat(2,1fr'}
                ai={'center'}
            >
                <GridInner>
                    <h1>{props.title}</h1>
                </GridInner>
                <GridInner
                    jc={'flex-end'}
                    display={'flex'}
                >
                    {props.children}
                </GridInner>
            </Grid>
        </Container>
    )
}

export default ActionHeader;