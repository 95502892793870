import React, { useState, useEffect, useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import UserContext from '@Context/User';

const RoleRouting = ({
    component: Component,layout: Layout, roles, ...rest
  }) =>{

    const [granted, setGranted] = useState(null);
    const { user } = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {

        grantPermission(roles)
        
     }, [roles]);

     const grantPermission = (requestedRoles) => {
        let grantFlag = false
        if(requestedRoles != undefined){
            requestedRoles.map(function (role) {
                    if(user){
                        if(role === user.is_admin){
                            grantFlag = true
                        }
                    }else{
                        grantFlag = false
                    }
            });
        }else{
            grantFlag = true 
        }

        setGranted(grantFlag)
    };

    return(
        <div>
            {granted != null &&
                <div>
                { granted && user &&
                <Route
                {...rest} render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
                />
                }
                { !granted && user &&
                    history.push('/')
                }
                { !granted && !user && 
                    history.push('/login')
                }
                { granted && !user && 
                <Route
                {...rest} render={props => (
                    <Layout>
                    <Component {...props} />
                    </Layout>
                )}
                />
                }
                
                </div>
            }
      </div>
    )
}

export default RoleRouting;