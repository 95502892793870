import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React from 'react';
import Routes from './containers/Routes';
import Theme from './theme/Theme';
import { UserProvider } from './context/User';
import { withToastProvider } from './components/Toast';
import { ProgressProvider } from './context/Progress'


function App() {
  return (
    <UserProvider>
      <ProgressProvider>
        <Router>
          <Theme>
            <Routes/> 
          </Theme>
        </Router>
      </ProgressProvider>
    </UserProvider>
  );
}

export default withToastProvider(App);
