import React from 'react';
import Modal from 'react-modal';
import { useThemeUI } from 'theme-ui';
import { 
    ConfirmationButtons,  
    ConfirmationOK,
    ConfirmationCancel,
    Message,
} from './styled';

const Confirmation = ({
    cancelAction,
    confirmAction,
    message,
    open,
    close
}) =>{

    const context = useThemeUI()
    const { theme } = context

    const customStyles = {
        content:{
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            top: 0,
            backgroundColor: theme.colors.mdgrey,
            border:`1px solid ${theme.colors.ltgrey}`,
            width:'300px',
            padding:0,
            boxShadow: '0 0 10px 10px rgba(30, 33, 37, 0.4)'
        },
        overlay:{
            backgroundColor:'transparent'
        }
    };

    return(

        <Modal
            isOpen={open}
            closeTimeoutMS={500}
            onRequestClose={close}
            style={customStyles}
            contentLabel="Confirmation"
            ariaHideApp={false}
            >
                <Message>{message}</Message>
                <ConfirmationButtons>
                    <ConfirmationCancel borderColor={theme.colors.ltgrey} backgroundColor={theme.colors.mdgrey} onClick={() => cancelAction()} hoverColor={theme.colors.ltgrey}>CANCEL</ConfirmationCancel>
                    <ConfirmationOK borderColor={theme.colors.ltgrey} backgroundColor={theme.colors.mdgrey} hoverColor={theme.colors.ltgrey} onClick={() => confirmAction()}>OK</ConfirmationOK>
                </ConfirmationButtons>
        </Modal>
    )
}

export default Confirmation;