import styled from 'styled-components';

export const InputWrap = styled.div`
display:grid;
grid-template-columns:1fr;
`

export const inputStyles = {
borderBottom:'1px solid grey',
height:32,
marginBottom:20,
paddingLeft:0,
paddingRight:0,
borderLeft:'none',
borderRight:'none',
borderTop:'none',
color:'white',
fontSize:16
}

export const labelStyles = {
marginBottom:10
}