import React from 'react';
import { Field } from 'formik';
import { useThemeUI } from 'theme-ui';
import { InputWrap, inputStyles, labelStyles } from './styled';

const FormTextArea = ({
    label,
    name,
    type = 'textarea',
    mt
}) =>{

    const context = useThemeUI()
    const { theme } = context

    const themeInputStyles = {
        fontFamily:theme.fonts.body,
        background:theme.colors.secondary,
        borderBottom:`1px solid ${theme.colors.ltgrey}`,
        resize:'none',
    }

    const wrapStyles = {
        marginTop:mt
    }

    const themeLabelStyles = {
        color:theme.colors.ltgrey
    }

    return(
         <InputWrap style={wrapStyles}>
            <label style={{...labelStyles, ...themeLabelStyles}}>{label}</label>
            <Field component="textarea" style={{...inputStyles, ...themeInputStyles}} type={type} name={name} rows="4"/>
        </InputWrap>
    )
}

export default FormTextArea;