import React from 'react';
import { StyledDelete } from './styled';
import { useThemeUI } from 'theme-ui';
import { ReactSVG } from 'react-svg';
import Trash from '@Assets/images/trash.svg';
 
const DeleteButton = ({
    ml,
    mr,
    action
}) =>{

    const context = useThemeUI()
    const { theme } = context

    const themeStyles = {
        color:theme.colors.red,
        marginLeft:ml,
        marginRight:mr
    }

    return(
        <StyledDelete onClick={() => action()} type="button" style={themeStyles}><ReactSVG
        beforeInjection={(svg) => {
            svg.setAttribute('style', `width: 12px;height:auto;fill:${theme.colors.red};display:flex;margin-right:5px`)
        }} 
        src={Trash} />DELETE</StyledDelete>
    )
}

export default DeleteButton;