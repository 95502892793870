import React, { useEffect, useState, useContext } from 'react';
import PageWrap from '@Containers/PageWrap';
import CourseCard from '@Components/CourseCard';
import Grid from '@Theme/SlickGrid/Grid';
import Container from '@Theme/SlickGrid/Container';
import AddButton from '@Components/AddButton';
import ActionHeader from '@Containers/Admin/ActionHeader';
import { getCourses } from '@Services';
import LoadingBar from 'react-top-loading-bar';
import { useToast } from '@Components/Toast';
import { useThemeUI } from 'theme-ui';
import UserContext from '@Context/User';


const AdminCatalog = () =>{

    const { user } = useContext(UserContext);
    const toast = useToast();
    const context = useThemeUI()
    const { theme } = context
    var timeOutId = null
    var allProgress = 0

    useEffect(() => {
        getAllClientCourses()
        timeOutId = setInterval(() => waitToLoad(), 1000);
    },[]);

    const [courses, setCourses] = useState([]);
    const [progress, setProgress] = useState(0);

    const getAllClientCourses = () =>{

        const clientId = user.client_id

        getCourses({ clientId },{ onDownloadProgress: setProgress})
        .then((results) => {
            clearInterval(timeOutId)
            setProgress(0)
            setCourses(results.result)
        }).catch((error =>{
            toast.add("Something went wrong", theme.colors.red)
        }))

    }

    const waitToLoad = () =>{
        allProgress = allProgress + 10
        setProgress(allProgress)
    }


    return(
        <PageWrap>
            <LoadingBar
                color={theme.colors.green}
                progress={progress}
            />
            <Container
                pt={0}
                pb={0}
            >
                <ActionHeader
                    title={'Courses'}
                >
                    <AddButton path={'/course/add'}/>
                </ActionHeader>
                {courses.length > 0 &&
                <Grid
                    layout={'repeat(3,1fr'}
                    gg={20}
                >
                    {courses.map(function(course, idx){
                        return(
                            <CourseCard
                                key={idx}
                                title={course.name}
                                courseId={course.id}
                            />
                            )
                        })
                    }
                </Grid>
                }
            </Container>
        </PageWrap>
    )
}
export default AdminCatalog;