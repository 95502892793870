export const themevals = {
    colors:{
        background: '#1e2125',
        primary: '#1e2125',
        secondary: '#232629',
        text:'white',
        green: '#1eb768',
        red: '#fd4a40',
        ltgrey: '#5d5e62',
        mdgrey: '#313436'
    },
    fonts: {
		body: 'Work Sans, sans-serif',
    },
    styles: {
		root: {
            fontFamily: 'body',
            a:{
                color:'text',
                fontFamily: 'body',
            },
            h1:{
                fontSize:'26px',
                mt:0,
                mb:0
            }
        }
	}
};