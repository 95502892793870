import React from 'react';
import PageWrap from '@Containers/PageWrap';

const AdminVideos = () =>{
    return(
        <PageWrap>
            <h1>Videos</h1>
        </PageWrap>
    )
}
export default AdminVideos;