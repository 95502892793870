import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@Theme/SlickGrid/Container';
import PageWrap from '@Containers/PageWrap';
import Placemat from '@Components/Placemat';
import { Formik, Form } from 'formik';
import UserContext from '@Context/User';
import { useToast } from '@Components/Toast';
import { useThemeUI } from 'theme-ui';
import { getCourse, updateCourse } from '@Services';
import FormInput from '@Components/FormInput';
import FormTextArea from '@Components/FormTextArea';
import * as Yup from 'yup';
import { Col6 } from '@Theme/SlickGrid/Cols';
import FormBottom from '@Components/FormBottom';
import FormSave from '@Components/FormBottom/FormSave';
import CancelButton from '@Components/FormBottom/CancelButton';
import LoadingBar from 'react-top-loading-bar';
import BackButton from '@Components/BackButton';
import SectionLoader from '@Components/SectionLoader';
import DeleteButton from '@Components/FormBottom/DeleteButton';
import { deleteCourse } from '@Services';
import Confirmation from '@Components/Confirmation';


const CourseDetails = () =>{

    const { user } = useContext(UserContext);
    const toast = useToast();
    const context = useThemeUI()
    const { theme } = context
    const history = useHistory()
    const params = useParams()
    var timeOutId = null
    var allProgress = 0

    const [course, setCourse] = useState(null)
    const [progress, setProgress] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)

    const CourseSchema = Yup.object().shape({
		title: Yup.string().required('Required'),
		description: Yup.string().required('Required')
    });


    useEffect(() => {
        getCourseDetail()
        timeOutId = setInterval(() => waitToLoad(), 1000);
    },[]);

    const waitToLoad = () =>{
        allProgress = allProgress + 10
        setProgress(allProgress)
    }

    const getCourseDetail = () =>{

        const vars = {
            courseId: params.course_id,
            clientId: user.client_id
        }

        getCourse(vars,{ onDownloadProgress: setProgress})
        .then((results) => {
            clearInterval(timeOutId)
            setProgress(0)
            setCourse(results.result[0])
        }).catch((error =>{
            toast.add("Something went wrong", theme.colors.red)
        }))
    }
    

    const handleSubmit =  ({ title, description, setSubmitting }) => {
        
        const vars = {
            course_id: params.course_id,
            name:title,
            description:description
        }

        const data = {
            clientId: user.client_id,
        }

        updateCourse(data,vars,{ onUploadProgress: setProgress})
            .then((results) => {
            getCourseDetail()
            toast.add('Course Updated', theme.colors.green)
            setSubmitting(false);
        }).catch((error =>{
            toast.add("Something went wrong", theme.colors.red)
            setSubmitting(false)
        }))
    };

    const performDelete = () =>{

        const data = {
            clientId: user.client_id,
            courseId: params.course_id
        }

        deleteCourse(data,{ onUploadProgress: setProgress})
            .then((results) => {
            toast.add('Course Deleted', theme.colors.green)
            history.push('/courses')
        }).catch((error =>{
            toast.add("Something went wrong", theme.colors.red)
        }))

    }

    const confirmDelete = () =>{
        setModalOpen(true)
    }

    const closeModal = () =>{
        setModalOpen(false)
    }

    return(
        <div>
            <PageWrap>
                <LoadingBar
                    color={theme.colors.green}
                    progress={progress}
                />
                <Container
                    pt={0}
                    pb={20}
                >
                {course &&
                    <div>
                    <BackButton path={'/courses'} text={'Courses'} />
                    {console.log(course.name)}
                    <h1>Course - {course.name}</h1>
                    <Formik
                        initialValues={{
                            title: course.name,
                            description: course.description
                        }}
                        validateOnMount={true}
                        validationSchema={CourseSchema}
                        onSubmit={(values, { setSubmitting }) =>{
                            handleSubmit({ ...values, setSubmitting })
                        }
                        }
                        >
                        {({ isSubmitting, isValid }) => (
                            <Form noValidate autoComplete="off">
                                <Placemat mt={20}>
                                <Col6>
                                    <FormInput id="title" name="title" type="text" label="Title"/>
                                </Col6>
                                <FormTextArea id="description" name="description" label="Description" mt={20}/>
                                </Placemat>
                                <FormBottom
                                    actionsRight={[
                                    <FormSave active={isValid}/>
                                    ]}
                                    actionsLeft={[
                                    <DeleteButton action={() => confirmDelete()}/>
                                    ]}
                                />
                            </Form>
                        )}
                    </Formik>
                    </div>
                }
                {!course &&
                    <Placemat>
                        <SectionLoader bgColor={theme.colors.secondary}/>
                    </Placemat>
                }
                </Container>
            </PageWrap>
            <Confirmation
                open={modalOpen}
                close={closeModal}
                message={'Are you sure you want to delete this course? This action cannot be undone.'}
                cancelAction={closeModal}
                confirmAction={performDelete}
            />
        </div>
    )
}

export default CourseDetails;