import styled from 'styled-components';

export const SubmitButton = styled.button`
    border-radius:5px;
    height:32px;
    border:none;
    margin-top:20px;
    color:white;
    font-size:16px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    &:disabled{
        opacity:.5;
    }
`

export const SubmitWrap = styled.div`
    display:flex;
    justify-content:center;
`