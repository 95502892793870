import axios from 'axios';

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (401 === error.response.status) {
			console.log('unauthorized')
			return Promise.reject(error);
		} else {
			return Promise.reject(error);
		}
	}
);

axios.interceptors.request.use((config) => {
	let user = localStorage.getItem('user');
	if (user) {
		user = JSON.parse(user);
		config.headers.common.lmstoken = user.token;
	}
	return config;
});


const patchData = (res) => res.data;

export const login = (data) => axios.post(`/login`, data).then(patchData);

export const addCourse = (data, { onUploadProgress }) =>{ 
	return axios.post(`/clients/${data.clientId}/courses`, data, {
		onUploadProgress : (progressEvent) => {
			const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			onUploadProgress(progress);
		  }
	}).then(patchData)
};


export const updateCourse = (data, vars, { onUploadProgress }) =>{ 
	return axios.put(`/clients/${data.clientId}/courses`, vars, {
		onUploadProgress : (progressEvent) => {
			const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			onUploadProgress(progress);
		  }
	}).then(patchData)
};

export const getCourse = (data,{ onDownloadProgress }) =>{ 
	return axios.get(`/clients/${data.clientId}/courses/${data.courseId}`, {
		onDownloadProgress : (progressEvent) => {
			const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			onDownloadProgress(progress);
		  }
	}).then(patchData)
};

export const getCourses = (data,{ onDownloadProgress }) =>{ 
	return axios.get(`/clients/${data.clientId}/courses`, {
		onDownloadProgress : (progressEvent) => {
			const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			onDownloadProgress(progress);
		  }
	}).then(patchData)
};

export const deleteCourse = (data, { onUploadProgress }) =>{ 
	return axios.delete(`/clients/${data.clientId}/courses/${data.courseId}`, {
		onDownloadProgress : (progressEvent) => {
			const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
			console.log(progressEvent)
			onUploadProgress(progress);
		  }
	}).then(patchData)
};