import styled from 'styled-components';

export const StyledCancel = styled.button`
    height:32px;
    border:none;
    background:transparent;
    cursor:pointer;
    display:flex;
    align-items:center;
    font-weight:bold;
    color:white;
`