import React from "react";
import { ThemeProvider } from "theme-ui";

import { themevals } from "./themevals";

const Theme = ({ children }) => {
	return (
		<ThemeProvider theme={themevals}>
			{children}
		</ThemeProvider>
	);
};

export default Theme;
