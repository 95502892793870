import React from 'react';
import { ContainerDiv } from './styled';

const Container = ({
    pt = 40,
    pb = 40,
    pl = 0,
    pr = 0,
    mb = 0,
    mt = 0,
    height,
    display,
    ai,
    ac,
    as,
    ji,
    jc,
    js,
    bgc,
    ...props
}) =>{

    const styles = {
        paddingTop:pt,
        paddingBottom:pb,
        marginBottom:mb,
        marginTop:mt,
        height: height,
        display: display,
        alignItems: ai,
        alignContent: ac,
        alignSelf: as,
        justifyContent: jc,
        justifySelf: js,
        justifyItems: ji,
        backgroundColor:bgc
    }

    return(
        <ContainerDiv style={styles}>
            {props.children}
        </ContainerDiv>
    )
}

export default Container;