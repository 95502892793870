import React from 'react';
import { GridInnerDiv } from './styled';

const GridInner = ({
    js,
    ji,
    jc,
    as,
    ai,
    ac,
    display,
    ...props
}) =>{

    const styles = {
        justifySelf: js,
        justifyContent: jc,
        justifyItems: ji,
        alignSelf: as,
        alignContent: ac,
        alignItems: ai,
        display: display
    }

    return(
        <GridInnerDiv style={styles}>
            {props.children}
        </GridInnerDiv>
    )
}

export default GridInner;