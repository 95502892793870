import React, { useContext } from 'react';
import UserContext from '@Context/User';
import { useHistory } from 'react-router-dom';

const Logout = () =>{

    const { setUser } = useContext(UserContext);
    const history = useHistory();

    const logOut = (e) => {
        e.preventDefault()
        setUser(null);
        console.log('logging out')
        localStorage.removeItem('user');
        history.push('/');
    };
    
    return(
        <>
            <a href="/" onClick={(e) => logOut(e)}>Logout</a>
        </>
    )
}

export default Logout;