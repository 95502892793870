import React from 'react';
import { StyledCancel } from './styled';
import { ReactSVG } from 'react-svg';
import Close from '@Assets/images/delete.svg';

const CancelButton = ({
    action,
    ml,
    mr
}) =>{

    const themeStyles = {
        marginLeft:ml,
        marginRight:mr
    }

    return(
        <StyledCancel style={themeStyles} type="button" onClick={() => action()}><ReactSVG
        beforeInjection={(svg) => {
            svg.setAttribute('style', `width: 11px;height:auto;fill:white;display:flex;margin-right:5px`)
        }} 
        src={Close} />
            CANCEL
        </StyledCancel>
    )
}

export default CancelButton;