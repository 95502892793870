import React, { useEffect } from 'react';
import { NavWrap } from './styled';
import SidebarContainer from '../../containers/SidebarContainer';
import AdminLink from '../AdminLink';
import { ReactSVG } from 'react-svg';
import Zealogo from '@Assets/images/zealogia_logo.svg';
import Catalog from '@Assets/images/catalog.svg';
import Camera from '@Assets/images/video-camera.svg';
import Template from '@Assets/images/layout.svg';
import User from '@Assets/images/user.svg';
import Setting from '@Assets/images/setting.svg';
import { Link, withRouter } from "react-router-dom";


const Sidebar = (props) =>{

    useEffect(() => {
        
    });

    return(
        <SidebarContainer>
            <NavWrap>
                <Link to='/'>
                <ReactSVG 
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', 'width: 100px;margin-bottom:40px;padding-left:10px')
                    }} 
                    src={Zealogo} />
                </Link>
                <AdminLink link="/courses" text="Courses" icon={Catalog}/>
                <AdminLink link="/videos" text="Videos" icon={Camera}/>
                <AdminLink link="/templates" text="Templates" icon={Template}/>
                <AdminLink link="/users" text="Users" icon={User}/>
                <AdminLink link="/settings" text="Settings" icon={Setting}/>
            </NavWrap>
            {props.children}
        </SidebarContainer>
    )
}
export default withRouter(Sidebar);