import React from 'react';
import PageWrap from '@Containers/PageWrap';

const AdminTemplates = () =>{
    return(
        <PageWrap>
            <h1>Templates</h1>
        </PageWrap>
    )
}
export default AdminTemplates;