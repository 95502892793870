import styled from 'styled-components';

export const ConfirmationButtons = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
`

export const ConfirmationCancel = styled.button`
    color:white;
    border-style:solid;
    border-left-width:0px;
    border-top-width:1px;
    border-bottom-width:0px;
    border-right-width:0px;
    border-bottom-left-radius: 5px;
    padding-top:10px;
    padding-bottom:10px;
    cursor:pointer;
    background-color:${props => props.backgroundColor};
    border-color:${props => props.borderColor};
    &:hover{
        background-color:${props => props.hoverColor};
    }
`

export const ConfirmationOK = styled.button`
    color:white;
    border-style:solid;
    border-left-width:1px;
    border-top-width:1px;
    border-bottom-width:0px;
    border-right-width:0px;
    border-bottom-right-radius: 5px;
    padding-top:10px;
    padding-bottom:10px;
    cursor:pointer;
    background-color:${props => props.backgroundColor};
    border-color:${props => props.borderColor};
    &:hover{
        background-color:${props => props.hoverColor};
    }
`

export const Message = styled.div`
    padding:10px;
`