import React from 'react';
import { SubmitButton, SubmitWrap } from './styled';
import ReactLoading from 'react-loading';
import { useThemeUI } from 'theme-ui';

const ZSubmit = ({
    text,
    disabled,
    width,
    loading
    }) => {

    const context = useThemeUI()
    const { theme } = context

    return(        
    <SubmitWrap>
        <SubmitButton 
            style={{width:width, fontFamily:theme.fonts.body, background:theme.colors.green}} 
            disabled={disabled} 
            type="submit"
        >
            {loading ? <ReactLoading className="loader" type="spin" height={20} width={20}/> : text}
        </SubmitButton>
    </SubmitWrap>
    )
}

export default ZSubmit;