import React from 'react';
import { GridDiv } from './styled';

const Grid = ({
    layout,
    ai,
    as,
    ac,
    ji,
    js,
    jc,
    gg,
    pt,
    pb,
    pl,
    pr,
    pd,
    ...props
}) =>{

    const styles = {
        alignItems: ai,
        alignSelf: as,
        alignContent: ac,
        justifySelf: js,
        justifyContent: jc,
        justifyItems: ji,
        gridGap: gg,
        gridTemplateColumns: layout,
        paddingTop: pt,
        paddingBottom: pb,
        paddingRight: pr,
        paddingLeft:pl,
        padding:pd
    }

    return(
        <GridDiv style={styles}>
            {props.children}
        </GridDiv>
    )
}

export default Grid;