import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@Theme/SlickGrid/Container';
import PageWrap from '@Containers/PageWrap';
import Placemat from '@Components/Placemat';
import { Formik, Form } from 'formik';
import FormInput from '@Components/FormInput';
import FormTextArea from '@Components/FormTextArea';
import * as Yup from 'yup';
import { Col6 } from '@Theme/SlickGrid/Cols';
import FormBottom from '@Components/FormBottom';
import FormSave from '@Components/FormBottom/FormSave';
import { addCourse } from '@Services'
import UserContext from '@Context/User';
import { useToast } from '@Components/Toast';
import { useThemeUI } from 'theme-ui';
import CancelButton from '@Components/FormBottom/CancelButton';
import LoadingBar from 'react-top-loading-bar';
import BackButton from '@Components/BackButton';

const CourseAdd = () =>{

    const { user } = useContext(UserContext);
    const toast = useToast();
    const context = useThemeUI()
    const { theme } = context
    const history = useHistory()

    const [progress, setProgress] = useState(0)
    const [course, setCourse] = useState(null)

    const CourseSchema = Yup.object().shape({
		title: Yup.string().required('Required'),
		description: Yup.string().required('Required')
    });

    useEffect(() => {
        if(course){
            setProgress(0)
            const timer = setTimeout(() => {
                loadFinished();
            }, 1800);
        }
    },[course]);


    const handleSubmit =  ({ title, description, setSubmitting }) => {
            const clientId = user.client_id
            const name = title
            addCourse({ name, description, clientId },{ onUploadProgress: setProgress})
            .then((results) => {
            setCourse(results)
            toast.add('Course Saved', theme.colors.green)
            setSubmitting(false);
        }).catch((error =>{
            toast.add("Something went wrong", theme.colors.red)
            setSubmitting(false)
        }))
    };

    const cancelAction = () =>{
        history.push('/courses')
    }

    const loadFinished = () =>{
        if(course){
            setProgress(0)
            history.push(`/course/${course.result.insertId}`)
        }
    }
    
    
    return(
        <PageWrap>
            <LoadingBar
                color={theme.colors.green}
                progress={progress}
                onLoaderFinished={() => loadFinished()}
            />
            <Container
                pt={0}
                pb={20}
            >
                <BackButton path={'/courses'} text={'Courses'} />
                <h1>Add Course</h1>
                    <Formik
                    initialValues={{
                        title: '',
                        description: ''
                    }}
                    validateOnMount={true}
                    validationSchema={CourseSchema}
                    onSubmit={(values, { setSubmitting }) =>{
                        handleSubmit({ ...values, setSubmitting })
                    }
                    }
                    >
                    {({ isSubmitting, isValid }) => (
                        <Form noValidate autoComplete="off">
                            <Placemat mt={20}>
                            <Col6>
                                <FormInput id="title" name="title" type="text" label="Title"/>
                            </Col6>
                            <FormTextArea id="description" name="description" label="Description" mt={20}/>
                            </Placemat>
                            <FormBottom
                                actionsRight={[
                                <FormSave active={isValid}/>
                                ]}
                                actionsLeft={[
                                <CancelButton action={cancelAction}/>
                                ]}
                            />
                        </Form>
                    )}
                </Formik>
            </Container>
        </PageWrap>
    )
}

export default CourseAdd;