import React from 'react';
import { useContext } from 'react';
import { Formik, Form } from 'formik';
import UserContext from '@Context/User';
import ZInput from '../ZInput';
import ZSubmit from '../ZSubmit';
import * as Yup from 'yup';
import { login } from '@Services';
import axios from 'axios';
import { LoginMat, LoginHeading } from './styled';
import { useHistory } from 'react-router-dom';
import { useToast } from '../Toast';
import { useThemeUI } from 'theme-ui';

const LoginForm = () =>{

    const { setUser } = useContext(UserContext);
	const history = useHistory();
	const toast = useToast();
	const context = useThemeUI()
    const { theme } = context

    const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Invalid email address').required('Required'),
		password: Yup.string().required('Required')
    });
    
    const handleSubmit = async ({ email, password, setSubmitting }) => {

		try {
			const user = await login({ email, password });

			/* Finish the cycle */
			setSubmitting(false);

			/* Set User Context */
			setUser({ ...user, email });

			/* Store user data in the local storage */
			localStorage.setItem('user', JSON.stringify({ ...user, email }));

            history.push('/')
		} catch (error) {
			toast.add(error.response.data.error, theme.colors.red)
			setSubmitting(false)
		}
	};
    
    return(
        <LoginMat style={{background:theme.colors.secondary}}>
			<LoginHeading>Login</LoginHeading>
            <Formik
				initialValues={{
					email: '',
					password: ''
				}}
				validationSchema={LoginSchema}
				validateOnMount={true}
				onSubmit={(values, { setSubmitting }) =>{
                    handleSubmit({ ...values, setSubmitting })
                }
				}
			>
                {({ isSubmitting, isValid }) => (
					<Form noValidate>
						<ZInput id="email" name="email" type="email" label="Email"/>
						<ZInput id="password" name="password" type="password" label="Password"/>
						<ZSubmit text="Login" disabled={!isValid || isSubmitting} loading={isSubmitting} width={100}/>
					</Form>
            )}
			</Formik>
        </LoginMat>
    )
}

export default LoginForm;