import styled from 'styled-components'

export const LinkWrap = styled.div`
    width:100%;
    margin-bottom:20px;
    display:grid;
    grid-template-columns:15px calc(100% - 15px);
    padding:10px;
    border-radius:5px;
`
export const IconWrap = styled.div`
    width:100%;
`
export const IconWrapStyles = {
    alignSelf:'center'
}
export const LinkWrapStyles = {
    alignSelf:'center',
    paddingLeft:'10px'
}
