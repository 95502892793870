import React from 'react';
import { useHistory } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import Plus from '@Assets/images/plus.svg';
import { AddButtonWrap } from './styled';
import { useThemeUI } from 'theme-ui';

const AddButton = (props) =>{

    const context = useThemeUI()
    const { theme } = context
    const history = useHistory()

    const styles = {
        background:theme.colors.green
    }

    const completeAction = () =>{
        if(props.path){
            history.push(props.path)
        }
    }

    return(
        <AddButtonWrap onClick={() => completeAction()} style={styles}>
            <ReactSVG
                    style={{height:15, width:15}}
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', `width:15px;height:auto;fill:white;`)
                    }} 
                    src={Plus} />
        </AddButtonWrap>
    )
}

export default AddButton;