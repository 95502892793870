import React from 'react';
import { PageWrapDiv} from './styled';

const PageWrap = (props) =>{
    return(
        <PageWrapDiv>
            {props.children}
        </PageWrapDiv>
    )
}
export default PageWrap;