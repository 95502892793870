import styled from 'styled-components';

export const LoginMat = styled.div`
    max-width:400px;
    position:relative;
    top:50%;
    left:50%;
    transform:translateY(-50%) translateX(-50%);
    padding:40px;
    border-radius:5px;
`

export const LoginHeading = styled.h1`
    text-align:center;
    margin-bottom:20px;
`