import React from 'react';
import { SaveButton } from './styled';
import { useThemeUI } from 'theme-ui';
import { ReactSVG } from 'react-svg';
import Disk from '@Assets/images/floppy-disk.svg';


const FormSave = (props) =>{

    const context = useThemeUI()
    const { theme } = context

    const themeStyles = {
        color:theme.colors.green
    }

    const activeStyles = {
        opacity:props.active ? 1 : 0.5
    }

    return(
        <SaveButton disabled={!props.active} style={{...themeStyles, ...activeStyles}} type="submit"><ReactSVG
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', `width: 11px;height:auto;fill:${theme.colors.green};display:flex;margin-right:5px`)
                    }} 
                    src={Disk} />
                SAVE
                    
        </SaveButton>
    )
}

export default FormSave;