import styled from 'styled-components';

export const ToastWrapper = styled.div`
    position: absolute;
    left: 50%;
    transform:translateX(-50%);
`
export const ToastDiv = styled.div`
    border: 2px solid transparent;
    border-radius: 4px;
    max-width: 480px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
    margin-top: 16px;
    display: flex;
    position: relative;
    cursor: pointer;
`
export const ToastText = styled.div`
    padding: 8px 24px;
    line-height: 1;
`
export const CloseButtonWrap = styled.div`
    display:flex;
    align-items:center;
`
export const CloseButton = styled.div`
    border: none;
    background-color: transparent;
    font-size: 16px;
    margin-right: 8px;
    cursor: pointer; 
    line-height:1;
    > div{
        display:flex;
        align-items:center;
        > div{
            display:flex;
            align-items:center;
        }
    } 
`
