import React, { useEffect, useRef, useState } from 'react';
import { ToastWrapper, ToastDiv, ToastText, CloseButton, CloseButtonWrap } from './styled';
import CloseIcon from '@Assets/images/cross.svg';
import { ReactSVG } from 'react-svg';
import './styles.css';

const Toast = ({ children, remove, color }) =>{

  const removeRef = useRef();
  removeRef.current = remove;

  const [appear, setAppear] = useState(false);

  useEffect(() => {

    const duration = 5000;
    const id = setTimeout(() => removeRef.current(), duration);

    setTimeout(function(){ makeAppear(); }, 100);
    setTimeout(function(){ makeGone(); }, 3000);

    return () => clearTimeout(id);
  }, []);

  const makeAppear = () =>{
    setAppear(true)
  }

  const makeGone = () =>{
    setAppear(false)
  }

  

  return (
    <ToastWrapper className={appear ? 'fade_in' : 'fade_out'}>
    <ToastDiv style={{background:color}}>
      <ToastText>
        { children }
      </ToastText>
      <CloseButtonWrap>
        <CloseButton onClick={remove}>
          <ReactSVG 
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 30px;height:30px;fill:white;')
            }} 
            src={CloseIcon}/>
        </CloseButton>
      </CloseButtonWrap>
    </ToastDiv>
    </ToastWrapper>
  );
}

export default Toast;