import React, { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import {LinkWrap, IconWrap, IconWrapStyles, LinkWrapStyles} from './styled'
import { ReactSVG } from 'react-svg';
import { useThemeUI } from 'theme-ui';

const AdminLink = (props) =>{

    useEffect(() => {

        const rootPath = props.match.path.split('/')
        
        //if link matches the current path
        if(props.match.path === props.link){
            setActive(true)
        //if the first part of path plus an s matches link
        }else if(`/${rootPath[1]}s` === props.link){
            setActive(true)
        }else{
            setActive(false)
        }

    },[props.link, props.match.path]);

    const [active, setActive] = useState(false)
    const context = useThemeUI()
    const { theme } = context

    return(
        <LinkWrap style={{background: active ? theme.colors.green : 'transparent'}}>
            <IconWrap style={IconWrapStyles}><ReactSVG
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', `width: 15px;height:auto;fill:white;opacity:${active ? '1' : '.4'};display:flex`)
                    }} 
                    src={props.icon} /></IconWrap>
            <Link style={LinkWrapStyles} to={props.link}>{props.text}</Link>
        </LinkWrap>
    )
}

export default withRouter(AdminLink);