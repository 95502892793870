import React from 'react';
import { BackButtonWrap } from './styled';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Left from '@Assets/images/left.svg';

const BackButton = ({
    path,
    text
}) =>{

    const history = useHistory()

    const goBack = () =>{
        history.push(path)
    }

    const cursorStyle = {
        cursor:'pointer'
    }

    return(
        <BackButtonWrap><ReactSVG
            beforeInjection={(svg) => {
                svg.setAttribute('style', `width: 25px;height:auto;fill:white;display:flex`)
            }} 
            src={Left} 
            onClick={() => goBack()}
            style={cursorStyle}
            />
            <div style={cursorStyle} onClick={() => goBack()}>{text}</div>
        </BackButtonWrap>
    )
}

export default BackButton;